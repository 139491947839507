<template>
  <div class="mt-[22px] form-log">
    <span class="text-sm text-ems-gray500 block mb-1">{{ t('log.username') }}</span>
    <span
      class="text-base text-ems-gray300 px-3 py-2 bg-ems-gray700 block mb-3"
      >{{ formState.username }}</span
    >
    <span class="text-sm text-ems-gray500 block mb-1">{{
      t('log.hoTen')
    }}</span>
    <span
      class="text-base text-ems-gray300 px-3 py-2 bg-ems-gray700 block mb-3"
      >{{ formState.fullName }}</span
    >
    <span class="text-sm text-ems-gray500 block mb-1">{{
      t('log.thaoTac')
    }}</span>
    <span
      class="text-base text-ems-gray300 px-3 py-2 bg-ems-gray700 block mb-3"
      >{{ formState.action }}</span
    >
    <span class="text-sm text-ems-gray500 block mb-1">{{
      t('log.chucNang')
    }}</span>
    <span
      class="text-base text-ems-gray300 px-3 py-2 bg-ems-gray700 block mb-3"
      >{{ formState.function }}</span
    >
    <span class="text-sm text-ems-gray500 block mb-1">{{
      t('log.status')
    }}</span>
    <span
      class="text-base px-3 py-2 bg-ems-gray700 block mb-3"
      :class="
        formState.status === 'Thành công'
          ? 'text-ems-boTro4_600 '
          : 'text-ems-main2'
      "
      >{{ formState.status }}</span
    >

    <span class="text-sm text-ems-gray500 block mb-1">{{
      t('log.thoiGian')
    }}</span>
    <span
      class="text-base text-ems-gray300 px-3 py-2 bg-ems-gray700 block mb-3"
      >{{ formatDate(formState.time, 'HH:mm:ss DD/MM/YYYY') }}</span
    >
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { ACTION } from '@/config/Constant.js';
import { formatDate } from '@/util/common-utils';

const { t } = useI18n();
const props = defineProps({
  formState: {
    default: {},
  },
});
</script>
<style lang="scss">

</style>
